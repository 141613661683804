import {Button} from 'antd';
import React, {useState} from 'react';
import styled from 'styled-components';
import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import SwipeableViews from 'react-swipeable-views';
import {virtualize} from 'react-swipeable-views-utils';
import {mod} from 'react-swipeable-views-core';

const SliderContainer = styled.div`
  position: relative;
  top: ${props => props.height < 437 ? 30 : 50}px;
  max-width: ${props => props.maxWidth}px;
  width: 100%;
  min-height: ${props => props.height || 400}px;
  background: rgb(11, 10, 10);
  @media (max-width: 991px) {
    margin-top: 300px;
  }
  @media (min-width: ${props => props.maxWidth}px) {
    margin: 0 auto;
  }
`

const SliderItems = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  background: rgb(11, 10, 10);
  align-items: center;
  position: relative;
  min-height: inherit
`
const NavButtonStyles = {
    width: "68px",
    height: "34px",
    paddingTop: "4px",
    borderRadius: "inherit",
    background: "inherit",
    borderColor: "inherit",
    zIndex: 5
}

const HideArrowDiv = styled.div`
  background: #1890ff;
  border-color: #1890ff;
  height: 50%;
  zIndex: 5;
  border-radius: ${props => props.direction === "left" ? "8px 8px 0 0" : "0 0 8px 8px"};

  :hover {
    background: white;
    border-color: white;
  }

  @media (max-width: 991px) {
    display: none;
  }
`

const TestDiv = styled.div`
  position: absolute;
  left: 125px;
  width: 76%;
  display: flex;
  align-items: center;
  z-index: 100;
  @media (max-width: 1530px) {
    width: 76%;
  }
  @media (max-width: 1450px) {
    width: 75%;
  }
  @media (max-width: 1400px) {
    width: 73%;
  }
  @media (max-width: 1350px) {
    width: 72%;
  }
  @media (max-width: 1300px) {
    width: 70%;
  }
  @media (max-width: 1270px) {
    width: 69%;
  }
  @media (max-width: 1215px) {
    width: 67%;
  }
  @media (max-width: 1200px) {
    width: 76%;
  }
  @media (max-width: 1170px) {
    width: 74%;
  }
  @media (max-width: 1120px) {
    width: 73%;
  }
  @media (max-width: 1070px) {
    width: 71%;
  }
  @media (max-width: 1020px) {
    width: 70%;
  }
`

const ArrowButton = styled.div`
  position: absolute;
  right: 0px;
  width: 68px;
  height: 68px;
`

const InfoCard = styled.div`
  width: 400px;
  min-width: 280px;
  min-height: 447px;
  position: absolute;
  transition: left 0.5s;
  left: -65px;
  background: #4285F4;
  color: white;
  border-radius: 16px;
  z-index: 10;
  padding: 46px 44px;
  @media (max-width: 1400px) {
    left: -20px;
    width: 320px;
    padding: 19px 20px 2px 35px;
    min-height: 390px;
  }
  @media (max-width: 1300px) {
    width: 320px;
    min-height: 340px;
  }
  @media (max-width: 1200px) {
    width: 270px;
    min-height: 300px;
  }
  @media (max-width: 1100px) {
    width: 256px;
    min-width: 240px;
    min-height: 280px;
  }
  @media (max-width: 991px) {
    min-height: auto;
    width: 255px;
    min-width: 260px;
    position: absolute;
    left: 55px;
    top: 75px;
    background: #1C1A1A;
    color: white;
    border-radius: 16px;
    padding: 24px 18px;
    z-index: 10;
  }
  @media (max-width: 600px) {
    left: 45px;
    top: 90px;
  }
  @media (max-width: 380px) {
    left: 25px;
    top: 65px;
    min-width: auto;
    width: 78%;
  }
`

const SmallInfoCard = styled.div`
  width: 255px;
  min-width: auto;
  height: 290px;
  position: absolute;
  left: 55px;;
  top: -200px;
  background: #4285F4;
  color: white;
  border-radius: 16px;
  padding: 28px 18px;
  z-index: 10;
  @media (max-width: 600px) {
    left: 45px;
    min-width: auto;
    width: 230px;
    height: auto;
    padding: 10px 16px;
  }
  @media (max-width: 380px) {
    left: 25px;
    min-width: auto;
    width: 200px;
  }
`

const InfoCardTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 5px;
  @media (max-width: 1200px) {
    font-size: 20px;
    font-weight: 400;
  }
  @media (max-width: 380px) {
    font-size: 16px;
  }
`

const InfoCardLine = styled.div`
  font-size: 20px;
  margin: 26px 0;
  font-weight: 400;
  @media (max-width: 1200px) {
    font-weight: 300;
    margin: 10px 0;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 380px) {
    font-size: 14px;
  }
`

const Item = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
`

const ItemImg = styled.div`
  width: 90% !important;
  display: flex;
  align-items: center;
  & img {
    width: 100% !important;
    margin: 0 auto;
    border-radius: 14px;
    height: 500px;
    object-fit: cover;
    transition: opacity 0.5s;
    opacity: ${props => props.active ? 1 : 0.4};
    @media (max-width: 700px) {
      height: 310px;
    }
    @media (max-width: 560px) {
      height: 290px;
    }
    @media (max-width: 380px) {
      height: 220px;
    }
  }
`

const Item2 = styled.div`
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 900px;
`

const ItemImg2 = styled.div`
  width: 90% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 90px;
  transition: transform 0.5s, opacity 0.5s;
  opacity: ${props => props.active ? 1 : 0.4};
  transform: scale(${props => props.active ? 1.2 : 1});

  @media (max-width: 1300px) {
    margin: 0 100px;
    justify-content: flex-start;
  }

  & img {
    max-height: 620px;
    max-width: 860px;
    height: 600px;
    object-fit: cover;
    width: 100% !important;
    margin: 0 auto;
    border-radius: 10px;
    @media (max-width: 1500px) {
      height: 560px;
    }
    @media (max-width: 1400px) {
      height: 520px;
    }
    @media (max-width: 1300px) {
      height: 500px;
    }
    @media (max-width: 1250px) {
      height: 460px;
    }
    @media (max-width: 1100px) {
      height: 410px;
    }
  }
`

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

function slideRenderer(params) {
    const {index, key, items, selectedItem} = params;

    return (
        <Item key={key}>
            <ItemImg active={selectedItem === index}>
                <img className="locked" src={items[mod(index, items.length)].localFile.publicURL} alt={"pic " + index } />
            </ItemImg>
        </Item>
    )
}

function slideRenderer2(params) {
    const {index, key, items, selectedItem} = params;

    return (
        <Item2 key={key}>
            <ItemImg2 active={selectedItem === index}>
                <img className="locked" src={items[mod(index, items.length)].localFile.publicURL}alt={"pic " + index } />
            </ItemImg2>
        </Item2>
    )
}

const springConfig = {
    duration: "0.8s",
    easeFunction: "cubic-bezier(0.1, 0.35, 0.2, 1)",
    delay: "0.05s",
};

const CarouselAboutOffice = ({items, contacts, nocard = false}) => {
    const screens = useBreakpoint();


    const [selectedItem, setSelectedItem] = useState(0);
    const [hoverArrow, setHoverArrow] = useState(null);

    const handleMouseEnter = direction => {
        setHoverArrow(direction);
    }

    const handleMouseLeave = () => setHoverArrow(null);


    return (
        <SliderContainer>

            {
                !screens.lg ?
                    <>
                        {
                            !nocard &&
                            <SmallInfoCard>
                                <InfoCardTitle>ОФИС КОМПАНИИ <br></br>И ШОУРУМ</InfoCardTitle>
                                <InfoCardLine>{contacts.schedule}</InfoCardLine>
                                <InfoCardLine>{contacts.adress}</InfoCardLine>
                                <InfoCardLine>{contacts.phone}</InfoCardLine>
                                <InfoCardLine>{contacts.email}</InfoCardLine>
                            </SmallInfoCard>
                        }

                        <VirtualizeSwipeableViews springConfig={springConfig} onChangeIndex={setSelectedItem}
                                                  index={selectedItem}
                                                  style={{padding: screens.xs ? "0 30px" : "0 80px"}}
                                                  slideRenderer={(e) => slideRenderer({...e, items, selectedItem})}/>

                    </>
                    :
                    <SliderItems>

                        <VirtualizeSwipeableViews slideStyle={{overflow: "initial"}} springConfig={springConfig}
                                                  onChangeIndex={setSelectedItem} index={selectedItem}
                                                  style={{padding: screens.xl ? "0 240px" : "0 150px"}}
                                                  slideRenderer={(e) => slideRenderer2({...e, items, selectedItem})}/>

                        <TestDiv>
                            {
                                !nocard &&
                                <InfoCard>
                                    <InfoCardTitle>ОФИС КОМПАНИИ <br></br>И
                                        ШОУРУМ</InfoCardTitle>
                                    <InfoCardLine>{contacts.schedule}</InfoCardLine>
                                    <InfoCardLine>{contacts.adress}</InfoCardLine>
                                    <InfoCardLine>{contacts.phone}</InfoCardLine>
                                    <InfoCardLine>{contacts.email}</InfoCardLine>
                                </InfoCard>
                            }


                            <ArrowButton>
                                <HideArrowDiv direction="left">
                                    <Button onClick={() => setSelectedItem(selectedItem - 1)} type="primary"
                                            onMouseEnter={() => handleMouseEnter("left")}
                                            onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                        <LeftArrow color={hoverArrow === "left" && "#4285F4"}/>
                                    </Button>
                                </HideArrowDiv>

                                <HideArrowDiv direction="right">
                                    <Button onClick={() => setSelectedItem(selectedItem + 1)} type="primary"
                                            onMouseEnter={() => handleMouseEnter("right")}
                                            onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                        <RightArrow color={hoverArrow === "right" && "#4285F4"}/>
                                    </Button>
                                </HideArrowDiv>
                            </ArrowButton>

                        </TestDiv>


                    </SliderItems>
            }


        </SliderContainer>
    )
}

export default CarouselAboutOffice;
